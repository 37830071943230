$(document).ready(function(){

    // back-to-top
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 110) {
            $("header").addClass('hidden');
        } else {
            $("header").removeClass('hidden');
        }
    });

});
